body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: #000080;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  background-color: #000080;
  padding: 20px;
}

.App-main {
  flex: 1;
  padding: 20px;
}

.App-footer {
  background-color: #000080;
  padding: 10px;
  text-align: center;
}
