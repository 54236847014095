/* Global Styles */
html {
    scroll-behavior: smooth;
}

/* Header Styles */
.header {
    background-color: #fff; /* Dark blue */
    color: #000000;
    padding: 20px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: fixed; /* Fix the header to the top */
    top: 0; /* Align it to the top of the viewport */
    left: 0; /* Align it to the left of the viewport */
    width: 100%; /* Ensure it spans the full width */
    z-index: 1000; /* Ensure it is above other content */
}

.logo-text{
    text-decoration: none;
    color: #000000;
    font-weight: 700;
    padding: 10px 15px;
    position: relative;
    left: -20px;
    font-size: 1.4em;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-family: Helvetica;
}

.logo-text:hover{
    background-color: #014150;
    color: #fff;
    border-radius: 5px;
}

.navbar-brand-container {
    display: flex;
    align-items: center;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.navbar-brand {
    display: flex;
    align-items: center;
}

.logo {
    height: 55px;
    width: auto;
    margin-right: 20px;
    border-radius: 60%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.logo:hover {
    box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.6);
    transform: scale(1.1);
}

.linkedin-icon {
    height: 40px;
    width: auto;
    margin-left: 15px;
    transition: transform 0.3s ease;
    position: relative;
    top: 7px;
    right: 5px;
}

.linkedin-icon:hover {
    transform: scale(1.1);
    filter: brightness(1.2);
}

.navbar-links {
    display: flex;
    gap: 20px;
}

.navbar-links a {
    color: #000000;
    text-decoration: none;
    padding: 10px 15px;
    font-size: 1.2em;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 5px;
    font-weight: 700;
}

.navbar-links a:hover {
    background-color: #014150;
    color: #fff;
    border-radius: 5px;
}

/* Hero Section Styles */
.hero {
    background: url('../Assets/hero-bg.jpg') no-repeat center center;
    background-size: cover; /* Ensure the image covers the entire section */
    background-attachment: fixed; /* Fix the background image so it scrolls slower */
    color: #fff;
    text-align: center;
    padding: 100px 20px; /* Adjust padding to ensure content is well-placed */
    position: relative;
    z-index: 1; /* Ensure content is above background */
}

.hero-content {
    position: relative;
    z-index: 2; /* Ensure text is above background image */
}

.hero-title {
    font-size: 4em;
    font-weight: bold;
    margin-bottom: 20px;
    animation: fadeIn 2s ease-out;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.6);
}

.hero-subtitle {
    font-size: 1.5em;
    margin-bottom: 30px;
    animation: fadeIn 3s ease-out;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

.hero-button {
    background-color: #ffffff;
    color: #004080;
    padding: 15px 30px;
    border: none;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1.2em;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
    display: inline-block;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.hero-button:hover {
    background-color: #000080;
    color: #f0f0f0;
    transform: scale(1.1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}



/* Split Section */
.split-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px; /* Similar height to the hero-bg */
    background-color: #f5f5f5;
    overflow: hidden;
}

.split-container {
    display: flex;
    width: 100%;
    /* max-width: 1200px; */
    height: 100%;
}

.split-left, .split-right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Text Box Styling */
.text-box {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    text-align: left;
    max-width: 80%;
}

.box-heading {
    font-size: 1.8rem;
    font-weight: bold;
    color: #004080;
}

.divider {
    width: 50px;
    height: 4px;
    background-color: #004080;
    margin: 20px 0;
}

.box-body {
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
}

/* Image Styling */
.split-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}




/* Product Section */
.section-title {
    text-align: center;
    font-size: 2.5em;
    font-weight: 700;
    color: #000000;
    font-family: Helvetica;
    z-index: 20;
    background-color: black;
}

.products {
    background-color: #f8f8f8;
    padding: 50px 0;
}

.product-item {
    display: flex;
    align-items: flex-start; /* Align items to the top to start overlapping */
    margin-bottom: 30px;
    position: relative; /* Positioning for overlap effect */
}

.product-item:nth-child(even) {
    flex-direction: row-reverse; /* Alternate left and right positioning */
    /* align-items: flex-end; */
}

.product-item .product-text {
    flex: 1;
    padding: 20px;
    color: #000000;
}

.product-item .product-text h3 {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 0px;
    color: #000000;
}

.product-item .product-text p {
    font-size: 1.2rem;
    line-height: 1.6;
}

.product-item .product-slideshow {
    flex: 1;
    position: relative;
}

.slideshow-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 1s ease-in-out; /* Smooth transition */
}

.slideshow-image:first-child {
    opacity: 1;
}

/* For reverse layout on alternate sections */
.product-reverse {
    flex-direction: row-reverse;
}

.product-slideshow img {
    animation: slideshow 3s infinite;
}

/* Media Queries for Responsive Layout */
@media (max-width: 768px) {
    .product-item {
        flex-direction: column;
    }

    .product-item .product-slideshow,
    .product-item .product-text {
        width: 100%;
    }

    .slideshow-image {
        height: 300px;
    }
}

.product-slideshow {
    height: 400px;
}

.slideshow-image.active {
    opacity: 1;
}

.slideshow-image:not(.active) {
    opacity: 0;
}









/* Contact Section Styles */
.contact {
    background-color: #ffffff;
    color: #333;
    padding: 60px 20px;
    text-align: center;
    position: relative;
}

.contact-button {
    background-color: #014859;
    color: #fff;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: inline-block;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.calendly-inline-widget {
    max-width: 100%;
    height: 100%;
}

.contact-button:hover {
    background-color: #014150;
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

/* Footer Styles */
.footer {
    background-color: #014859; /* Dark blue */
    color: #fff;
    padding: 40px 20px;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
}

.footer-info {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
}

.contact-info {
    flex: 1;
    margin: 0 20px;
}

.contact-info h3 {
    margin-bottom: 10px;
    font-size: 1.5em;
}

.contact-info p {
    margin: 5px 0;
}

.contact-info a {
    color: #fff;
    text-decoration: none;
}

.contact-info a:hover {
    text-decoration: underline;
}

.footer-note {
    margin-top: 20px;
    font-size: 0.9em;
}

/* Small LinkedIn Button Styles */
.linkedin-button {
    /* background-color: #0077b5; */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    /* transition: background-color 0.3s ease, transform 0.3s ease; */
    position: relative;
    top: 18px;
    scale: 1.1;
}

.linkedin-button img {
    width: 50px;
    height: 50px;
}

.linkedin-button:hover {
    /* background-color: #006093; */
    transform: scale(1.1); /* Slightly enlarge on hover */
    /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); */
    transition: all 0.3s ease;
}

/* Hamburger Menu Styles */
.hamburger {
    display: none; /* Hide by default on larger screens */
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 21px;
    cursor: pointer;
}

.hamburger div {
    width: 100%;
    height: 3px;
    background-color: white;
    transition: all 0.3s ease;
}

/* Active Menu Styles */
.navbar-links.active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 20px;
    background-color: #014150;
    border-radius: 5px;
    padding: 10px 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .navbar-links {
        flex-direction: column;
        align-items: center;
        display: none;
    }
    
    .navbar-links a {
        padding: 10px 0;
        font-size: 1em;
        text-align: center;
    }

    .hamburger {
        display: flex; /* Show hamburger on smaller screens */
        background-color: #014150;
    }

    .carousel-item {
        min-width: 80%;
    }

    .hero-title {
        font-size: 1.8em;
    }

    .hero-subtitle {
        font-size: 1em;
    }

    .contact-button {
        padding: 10px 20px;
        font-size: 1em;
    }
}

.about-us {
    position: relative;
    /* padding: 50px 0; */
    color: #fff; /* Default text color */
    overflow: hidden; /* Prevent overflow issues */
}

.about-background {
    background-image: url('../Assets/farmer.jpg'); /* Replace with your image path */
    background-size: cover; /* Cover entire section */
    background-position: center; /* Center the background image */
    padding: 50px 20px;
    position: relative; /* Positioning context for overlay */
    z-index: 0; /* Ensure the background is below the overlay */
}

.about-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
    z-index: -1; /* Ensure the overlay is behind the content */
    pointer-events: none; /* Allow interactions to pass through */
}







.container {
    position: relative; /* Ensure it is above the overlay */
    z-index: 20; /* Bring text above overlay */
    color: black;
}






.section-title {
    text-align: center;
    font-size: 2.5em;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 20px; /* Spacing below the title */
    font-family: 'Helvetica', sans-serif; /* Consistent font */
    position: relative;
    z-index: 20;
    background-color: #014859;
}

.section-description {
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.6;
    color: #ffffff; /* Lighter text for readability */
    padding: 0 20px; /* Padding on sides for smaller screens */
    max-width: 800px; /* Limit width for better readability */
    margin: 0 auto; /* Centering the description */
}

.section-description::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #00000020; /* Dark overlay */
    opacity: 0.5; /* Semi-transparent */
    z-index: -1; /* Behind the text */
    scale: 1.3;
}

.subsection-title {
    font-size: 2rem;
    font-weight: bold;
    color: #ffffff; /* Dark color for subsection titles */
    margin-top: 30px; /* Space above subsection titles */
    position: relative;
    z-index: 3;
}

@media (max-width: 768px) {
    .section-title {
        font-size: 2em; /* Adjust title size on small screens */
    }

    .subsection-title {
        font-size: 1.5rem; /* Adjust subsection title size */
    }

    .section-description {
        font-size: 1rem; /* Smaller text on mobile */
    }
}
