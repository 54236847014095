html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
